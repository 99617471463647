.appLogo {
  width: 90px;
  margin-left: 16px;
  margin-right: 24px;
}

.MuiAppBar-root {
  background: #ffffff !important;
  /* z-index: 9999999999 !important; */
}

.headerTop .MuiButton-root {
  color: #1f384c;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  margin-right: 32px !important;
}

.headerTop .MuiTypography-root {
  margin: 0 !important;
}

.hearderOption {
  color: #1f384c;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  margin-right: 32px !important;
  /* padding: 0 !important; */
}

.hearderOption .MuiTypography-root {
  /* margin: 0 !important; */
}

.createSurveyButton {
  color: white !important;
  background-color: #F56102 !important;
  border-color: #F56102 !important;
  border-radius: 5px !important;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 12px !important;
}

.showAll {
  color: #14009b;
  font-style: italic;
  font-weight: 700 !important;
  text-align: center !important;
  justify-content: center !important;
}

.createQuarterMenu {
  font-size: 12px !important;
  color: #333;
}

.createQuarterDropdown .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  max-height: 400px;
}

.createQuarterDropdown .MuiMenu-list {
  min-width: 190px !important;
}

.password_error_msg {
  font-size: 13px;
  color: #d32f2f;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
}

.passwordChangeField .fieldInput .MuiOutlinedInput-root {
  max-width: 100% !important;
}

.passwordChangeField .fieldInput {
  margin-bottom: 20px;
}

.cancelButton {
  color: white !important;
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  margin: 8px !important;
  width: 30%;
}

.saveButton {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 30%;
  margin: 8px 8px 0 !important;
}

.saveButtonOther {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 100%;
  margin: 8px 0 !important;
}

.cardModalButton {
  display: flex;
  justify-content: center;
}

ul li.MuiMenuItem-root:hover {
  background-color: #f1f1f1 !important;
}

.MuiAvatar-root {
  width: 35px !important;
  height: 35px !important;
  font-size: 1rem !important;
}

.passwordRules {
  text-align: left;
  font-size: 12px;
  color: #828282;
}

.passwordRules p {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .appLogo {
    margin-left: 0px;
    margin-right: 5px;
  }

  .hearderOption {
    margin-right: 5px !important;
    font-size: 10px !important;
    /* padding: 0 !important; */
  }

  .headerTop .MuiButton-root {
    margin-right: 5px !important;
    font-size: 9px !important;
  }

}

@media screen and (max-width: 1000px) {
  .headerTop {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .searchIcon {
    width: 4%;
    margin-right: 350px;
  }

  .appBarPopup {
    padding: 11px;
    display: flex;
    position: absolute;
    width: 96%;
    background-color: white;
  }
}

@media screen and (max-width: 600px) {
  .searchIcon {
    width: 13%;
    margin-right: 86px;
  }

  .appBarPopup {
    width: 95%;
  }
}

@media screen and (max-width: 400px) {
  .appBarPopup {
    width: 94%;
  }
}

@media screen and (max-width: 350px) {
  .searchIcon {
    width: inherit;
    margin-right: 0;
  }
}